import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../src/layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_5eecb0f3 from 'nuxt_plugin_plugin_5eecb0f3' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_image_690d24f8 from 'nuxt_plugin_image_690d24f8' // Source: ./image.js (mode: 'all')
import nuxt_plugin_markdownit_368390d6 from 'nuxt_plugin_markdownit_368390d6' // Source: ./markdownit.js (mode: 'all')
import nuxt_plugin_gtm_2e37c59f from 'nuxt_plugin_gtm_2e37c59f' // Source: ./gtm.js (mode: 'all')
import nuxt_plugin_recaptcha_5c919278 from 'nuxt_plugin_recaptcha_5c919278' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_axios_3f1b1eee from 'nuxt_plugin_axios_3f1b1eee' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginutils_0a9400d2 from 'nuxt_plugin_pluginutils_0a9400d2' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_6b97a19d from 'nuxt_plugin_pluginrouting_6b97a19d' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_072cf6f2 from 'nuxt_plugin_pluginmain_072cf6f2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_vuescrollto_4058cd40 from 'nuxt_plugin_vuescrollto_4058cd40' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_toast_a6ba8590 from 'nuxt_plugin_toast_a6ba8590' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_storage_5c132e64 from 'nuxt_plugin_storage_5c132e64' // Source: ./storage.js (mode: 'all')
import nuxt_plugin_apollomodule_9836b956 from 'nuxt_plugin_apollomodule_9836b956' // Source: ./apollo-module.js (mode: 'all')
import nuxt_plugin_vuejsmodal_0719e4a0 from 'nuxt_plugin_vuejsmodal_0719e4a0' // Source: ../src/plugins/vue-js-modal.js (mode: 'all')
import nuxt_plugin_strapi_6d97a117 from 'nuxt_plugin_strapi_6d97a117' // Source: ../src/plugins/strapi.js (mode: 'all')
import nuxt_plugin_auth_8fed78d0 from 'nuxt_plugin_auth_8fed78d0' // Source: ../src/plugins/auth.js (mode: 'all')
import nuxt_plugin_notifications_598635e6 from 'nuxt_plugin_notifications_598635e6' // Source: ../src/plugins/notifications.js (mode: 'all')
import nuxt_plugin_tippy_7dc94c6a from 'nuxt_plugin_tippy_7dc94c6a' // Source: ../src/plugins/tippy.js (mode: 'all')
import nuxt_plugin_filters_2b4f519a from 'nuxt_plugin_filters_2b4f519a' // Source: ../src/plugins/filters.js (mode: 'all')
import nuxt_plugin_previewclient_07e06caf from 'nuxt_plugin_previewclient_07e06caf' // Source: ../src/plugins/preview.client.js (mode: 'client')
import nuxt_plugin_axios_54e49ad0 from 'nuxt_plugin_axios_54e49ad0' // Source: ../src/plugins/axios.js (mode: 'all')
import nuxt_plugin_breakpoints_e577f0a8 from 'nuxt_plugin_breakpoints_e577f0a8' // Source: ../src/plugins/breakpoints.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Medituote","titleTemplate":"%s | Medituote","htmlAttrs":{"lang":"fi"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Default description"},{"hid":"og:title","name":"og:title","content":"Medituote"},{"hid":"og:type","name":"og:type","content":"website"},{"hid":"og:site_name","name":"og:site_name","content":"Medituote"},{"hid":"og:image","name":"og:image","content":"\u002Fdefault-og-image.jpg"},{"hid":"og:description","name":"og:description","content":"Default description"}],"script":[{"src":"https:\u002F\u002Fwww.termsfeed.com\u002Fpublic\u002Fcookie-consent\u002F4.1.0\u002Fcookie-consent.js","type":"text\u002Fjavascript","charset":"utf-8"},{"type":"text\u002Fjavascript","charset":"utf-8","innerHTML":"\n                const cookieConsentConfig = {\"notice_banner_type\":\"simple\",\"consent_type\":\"express\",\"palette\":\"light\",\"language\":\"fi\",\"page_load_consent_levels\":[\"strictly-necessary\"],\"notice_banner_reject_button_hide\":false,\"preferences_center_close_button_hide\":false,\"page_refresh_confirmation_buttons\":false,\"website_name\":\"Medituote\",\"website_privacy_policy_url\":\"d7d33364901b\u002Fstatic\u002Ftietosuojaseloste\"};\n                document.addEventListener('DOMContentLoaded', function() {\n                    cookieconsent.run(cookieConsentConfig);\n                });\n                "}],"__dangerouslyDisableSanitizers":["script"],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_5eecb0f3 === 'function') {
    await nuxt_plugin_plugin_5eecb0f3(app.context, inject)
  }

  if (typeof nuxt_plugin_image_690d24f8 === 'function') {
    await nuxt_plugin_image_690d24f8(app.context, inject)
  }

  if (typeof nuxt_plugin_markdownit_368390d6 === 'function') {
    await nuxt_plugin_markdownit_368390d6(app.context, inject)
  }

  if (typeof nuxt_plugin_gtm_2e37c59f === 'function') {
    await nuxt_plugin_gtm_2e37c59f(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_5c919278 === 'function') {
    await nuxt_plugin_recaptcha_5c919278(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3f1b1eee === 'function') {
    await nuxt_plugin_axios_3f1b1eee(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_0a9400d2 === 'function') {
    await nuxt_plugin_pluginutils_0a9400d2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_6b97a19d === 'function') {
    await nuxt_plugin_pluginrouting_6b97a19d(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_072cf6f2 === 'function') {
    await nuxt_plugin_pluginmain_072cf6f2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_4058cd40 === 'function') {
    await nuxt_plugin_vuescrollto_4058cd40(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_a6ba8590 === 'function') {
    await nuxt_plugin_toast_a6ba8590(app.context, inject)
  }

  if (typeof nuxt_plugin_storage_5c132e64 === 'function') {
    await nuxt_plugin_storage_5c132e64(app.context, inject)
  }

  if (typeof nuxt_plugin_apollomodule_9836b956 === 'function') {
    await nuxt_plugin_apollomodule_9836b956(app.context, inject)
  }

  if (typeof nuxt_plugin_vuejsmodal_0719e4a0 === 'function') {
    await nuxt_plugin_vuejsmodal_0719e4a0(app.context, inject)
  }

  if (typeof nuxt_plugin_strapi_6d97a117 === 'function') {
    await nuxt_plugin_strapi_6d97a117(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_8fed78d0 === 'function') {
    await nuxt_plugin_auth_8fed78d0(app.context, inject)
  }

  if (typeof nuxt_plugin_notifications_598635e6 === 'function') {
    await nuxt_plugin_notifications_598635e6(app.context, inject)
  }

  if (typeof nuxt_plugin_tippy_7dc94c6a === 'function') {
    await nuxt_plugin_tippy_7dc94c6a(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_2b4f519a === 'function') {
    await nuxt_plugin_filters_2b4f519a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_previewclient_07e06caf === 'function') {
    await nuxt_plugin_previewclient_07e06caf(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_54e49ad0 === 'function') {
    await nuxt_plugin_axios_54e49ad0(app.context, inject)
  }

  if (typeof nuxt_plugin_breakpoints_e577f0a8 === 'function') {
    await nuxt_plugin_breakpoints_e577f0a8(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
